<template>
  <section>
    <div class="content-header">
      <h2>Preparing for the unexpected</h2>
    </div>
    <div class="content-wrapper">
      <p>In the journal article, <a href="https://www.sciencedirect.com/science/article/abs/pii/S0090261616300699?via%3Dihub" target="_blank">Developing Career Resilience and Adaptability</a>, the authors remind us that “careers rarely unfold as intended or imagined. No matter how much people analyze their aspirations and opportunities in the service of planning their career, unexpected developments often bring unforeseen changes”. </p>
      <p>How can you help students build individual capacity to withstand career setbacks? Learn about the types of obstacles that could be encountered and discover some psychological and behavioural career strategies to overcome and even thrive in the context of career-shaping events.</p>
      <p>Psychological strategies include:</p>
      <ul>
        <li>Managing distracting emotions</li>
        <li>Nurturing a growth mindset</li>
        <li>Balancing and rebalancing career goals</li>
      </ul>
      <p>Behavioural strategies include:</p>
      <ul>
        <li>Developing effective relationships at school and in the workplace (or in and outside the classroom)</li>
        <li>Undertaking relevant training and development opportunities</li>
        <li>Developing an effective career network</li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
